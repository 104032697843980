//*// LOGIN FORM //*//

body.template-login_form #content-core,
body.template-logged_out #content-core {
	.portlet;
	background: @plone-portlet-footer-bg;
	max-width:300px;
	margin: 0 auto @plone-padding-base-vertical;
	padding: @plone-padding-base-vertical @plone-padding-base-horizontal;
}

//*// Login text placement
#portal-anontools {
	ul {padding-right: 250px;text-align: right; list-style: none}
	li {margin: @plone-padding-base-horizontal;}
}