//*// NEWS //*//

.newsImageContainer {
	float:right;
	margin: 0 0 @plone-padding-base-vertical @plone-padding-base-vertical;
	border-radius: @plone-border-radius-base;
	box-shadow: 0 1px 2px rgba(0,0,0,.17);
	overflow: hidden;

	figcaption {
		font-size: @plone-font-size-small;
		background: @plone-portlet-footer-bg;
	}
}