//*// BUTTONS //*//

.standalone, .context, .destructive, [type="submit"], button {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: @plone-font-weight-bold;
  text-align: center;
  text-shadow: 0 1px rgba(0,0,0,.25);
  vertical-align: middle;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  .button-size(@plone-padding-base-vertical; @plone-padding-base-horizontal; @plone-font-size-base; @plone-line-height-base; @plone-border-radius-small);
  .user-select(none);

  &,
  &:active,
  &.active {
    &:focus {
      .tab-focus();
    }
  }

  &:hover,
  &:focus {
    color: @plone-btn-standalone-color;
    text-decoration: none;
    .box-shadow(0 1px 2px rgba(0,0,0,.25));
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    .box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
    pointer-events: none; // Future-proof disabling of clicks
    .opacity(.5);
    .box-shadow(none);
  }
}

.standalone, [type="submit"], button { //gray
  .button-variant(@plone-btn-standalone-color; @plone-btn-standalone-bg; @plone-btn-standalone-border);
}

.context { //blue
  .button-variant(@plone-btn-context-color; @plone-btn-context-bg; @plone-btn-context-border);
}

.destructive { //red
  .button-variant(@plone-btn-destructive-color; @plone-btn-destructive-bg; @plone-btn-destructive-border);
}

.link-parent {
  .standalone; margin-bottom: @plone-padding-base-horizontal;
  &:before {content: "↩ ";top: 3px;position: relative;}
}

